<template>
  <!-- Order section -->
  <section class="order" :class="iscustomtitleflag == 0 ? 'delete_tag' : ''"> 
    <div class="container">
      <h2 class="section-heading" v-if="iscustomtitleflag == 1">
        {{ iscustomtitle }}
      </h2>
      <h2 class="section-heading" v-else></h2>
      <div class="carousel-wrap">
        <carousel
          :items="5"
          :margin="25"
          :autoWidth="false"
          v-if="propdata.length"
          :responsive="{0:{items:2,nav:true},600:{items:3,nav:true},1200:{items:5,nav:true}}"
          :navText="[initprevbutn,initnextbutn]"
        >
          <div class="item" v-for="(str,index) in propdata" v-bind:key="index">
            <div class="ordeer-box">
              <div class="product-image">
                <a :href="'category?categoryid='+str.category_id">
                  <v-lazy-image :src="str.category_big_icon" src-placeholder="str.category_big_icon" alt="product" />
                </a>
              </div>
              <h5>
                <a :href="'category?categoryid='+str.category_id">{{str.category_name}}</a>
              </h5>
            </div>
          </div>
        </carousel>
      </div>
    </div>
  </section>
</template>
<script>
import carousel from "vue-owl-carousel";
import VLazyImage from "v-lazy-image/v2";
export default {
  name: "OrderSection",
  props: {
    propdata: {
      type: Array,
      default: () => ["next", "prev"]
    },
    iscustomtitleflag:{
      type: String,
    },
    iscustomtitle:{
      type:String,
    },
  },
  components: {
    carousel,
    VLazyImage,
  },
  data() {
    return {
      initprevbutn:
        '<button type="button" role="presentation" class="owl-prev"><span aria-label="Previous">‹</span></button>',
      initnextbutn:
        '<button type="button" role="presentation" class="owl-next"><span aria-label="Next">›</span></button>',
      is_eatery:0
    };
  }
};
</script>
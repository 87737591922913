<template>
  <section class="wholesale-stores" v-if="is_wholeseller == 1">
    <div class="container">
      <h2 class="section-heading" v-if="iscustomtitleflag == 1">{{ iscustomtitle }}</h2>
      <h2 class="section-heading" v-else></h2>
      <store-block :storedata="propdata"></store-block>
    </div>
  </section>
</template>
<script>
import StoreBlock from "../store/StoreBlock";
export default {
  name: 'WholeSellerStores',
  props:{
    propdata:{
      type:Array,
    },
    iscustomtitle:{
      type:String,
    },
    iscustomtitleflag:{
      type: String,
    }
  },
  components:{
    "store-block": StoreBlock,
  },
  data() {
    return {
      is_wholeseller:"",
    }
  },
  mounted(){
    this.is_wholeseller = localStorage.getItem("is_wholeseller") 
  }
}
</script>
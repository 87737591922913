<template>
	<div class="container d-none d-md-block">
		<div :class="(is_business_model == 3) ? 'b2c-search-bar' : 'search-bar'">
			<form @submit.prevent="postSearchnew">
				<div class="form-row mx-0">
					<div class="form-group mb-0">
						<label for="staticEmail2" class="sr-only">search</label>
						<input
						type="text"
						class="form-control"
						id="staticEmail2"
						:placeholder="(is_business_model == 3) ? 'Search Bazaar' : 'Search for Item / Category / Store'"
						@keyup="searchAutoComplete"
						@focus="modal = true"
						v-model="searchqry"
						autocomplete="nope"
						/>
						<span  class="bg-white search-btn" @click="postSearchnew">
							<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" width="25.001px" height="25px" viewBox="123.295 0 25.001 25" enable-background="new 123.295 0 25.001 25" xml:space="preserve" v-if="is_business_model == 3">
								<g>
									<g>
										<g>
											<path fill="currentColor" d="M134.303,0c-6.069,0-11.008,4.938-11.008,11.01c0,6.07,4.938,11.01,11.008,11.01     c6.072,0,11.012-4.938,11.012-11.01C145.314,4.938,140.375,0,134.303,0z M134.303,19.986c-4.948,0-8.977-4.027-8.977-8.978     s4.028-8.978,8.977-8.978c4.951,0,8.979,4.027,8.979,8.978C143.279,15.959,139.254,19.986,134.303,19.986z"/>
										</g>
									</g>
									<g>
										<g>
											<path fill="currentColor" d="M147.997,23.266l-5.827-5.828c-0.396-0.396-1.041-0.396-1.436,0c-0.398,0.398-0.398,1.041,0,1.438     l5.824,5.826C146.76,24.9,147.018,25,147.279,25c0.259,0,0.52-0.1,0.718-0.298C148.395,24.307,148.395,23.662,147.997,23.266z"/>
										</g>
									</g>
								</g>
							</svg>
						</span>
						<div class="location-selector">
							<a class="popup-btn" href="javascript:void(0)" data-toggle="modal" @click="openLocationFrm"><span class="d-none d-md-block">{{address | formatAddress}}</span></a>
						</div>
					</div>

					<div class="auto-complete" v-if="auto_complete.length > 0 && modal && searchqry!=''">
						<ul class="auto-suggestion-dropdown">
							<li class="py-2 ml-3" @click="setState(result)" style="cursor:pointer;" :key="index" v-for="(result,index) in auto_complete">{{result.item_name}}</li>
						</ul>
					</div>


					<span href="javascript:void(0)" class="bg-white search-btn" @click="postSearchnew" v-if="is_business_model!=3">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" width="25.001px" height="25px" viewBox="123.295 0 25.001 25" enable-background="new 123.295 0 25.001 25" xml:space="preserve">
							<g>
								<g>
									<g>
										<path :fill="localconfig.css_button_customer_default" d="M134.303,0c-6.069,0-11.008,4.938-11.008,11.01c0,6.07,4.938,11.01,11.008,11.01     c6.072,0,11.012-4.938,11.012-11.01C145.314,4.938,140.375,0,134.303,0z M134.303,19.986c-4.948,0-8.977-4.027-8.977-8.978     s4.028-8.978,8.977-8.978c4.951,0,8.979,4.027,8.979,8.978C143.279,15.959,139.254,19.986,134.303,19.986z"/>
									</g>
								</g>
								<g>
									<g>
										<path :fill="localconfig.css_button_customer_default" d="M147.997,23.266l-5.827-5.828c-0.396-0.396-1.041-0.396-1.436,0c-0.398,0.398-0.398,1.041,0,1.438     l5.824,5.826C146.76,24.9,147.018,25,147.279,25c0.259,0,0.52-0.1,0.718-0.298C148.395,24.307,148.395,23.662,147.997,23.266z"/>
									</g>
								</g>
							</g>
						</svg>
					</span>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
	import helper from "../../store/helper";
	export default {
		name: "Searchbar",
		mixins: [helper],
		data() {
			return {
				store_id: '1',
				is_business_model: '0',
				searchqry: "",
				address: "",
				globalsettings: [],
				localconfig: "",
				profile: {
					user_id: ""
				},
				customer_location_type : 1,
				search_autocomplete:{
					search_query: "",
					store_id:"",
					limit:1,
					page:1
				},
				auto_complete:[],
				auto_complete_result:null,
				modal:false
			};
		},
		mounted() {
			this.is_business_model = localStorage.getItem("is_business_model");
			this.originalColor()
			this.customer_location_type = parseFloat(localStorage.getItem("customer_location_type"))

			if(localStorage.getItem("location_details") != null)
			{
				this.address = JSON.parse(localStorage.getItem("location_details")).address;
			}

			if(((localStorage.getItem("user") != null) ? JSON.parse(localStorage.getItem("user")).user_id : "0") != "0")
			{
				this.getUserAddressSearchBar()
			}
		},
		updated(){
			window.$(document).ready(function(){
				window.$('.search-btn svg path').css({fill: localStorage.getItem("button_default_color")})
			})
		},
		filters: {
			formatAddress: function(value) {
				if (value != null && value != undefined && value.length > 27) {
					return value.slice(0, 21) + "...";
				}
				return value;
			}
		},
		methods: {
			getUserAddressSearchBar(){
				if ((localStorage.getItem("address_set_type") != null && localStorage.getItem("address_set_type") != undefined))
				{
					this.profile.user_id = (localStorage.getItem("user") != null) ? JSON.parse(localStorage.getItem("user")).user_id : "0";
					this.$store.dispatch("customeraddress", this.profile).then(response => {
						var adrObject = response.data.filter(function(elem) {
							return elem.is_default == "1";
						});
						var city_name = adrObject[0].city_name1 + ' ' + adrObject[0].zipcode1 +', ';
						var state_name = (adrObject[0].state_name1 != '') ? adrObject[0].state_name1 + ', ' : '';
						var country = (adrObject[0].country_name1 != '') ? adrObject[0].country_name1 : '';
						var area_name = adrObject[0].area_name1 + ', ';
						this.address = area_name + city_name + state_name + country;
					})
				}
			},
			postSearchnew() {
				if(this.searchqry != ""){
					if(this.is_business_model == 3){
						localStorage.setItem("home-search", this.searchqry);
						this.store_id = localStorage.getItem("store_id");
						this.$router.push({ path: `/shopdetail?storeid=${this.store_id.toString()}` });
					} else {
						localStorage.setItem("searchqry", this.searchqry);
						this.$router.push({ name: "search" });
					}
				}
			},
			openLocationFrm() {
				window.$("#LocationSelector").modal("show");
			},
			setState(state){
				this.searchqry = state.item_name;
				this.store_id = localStorage.getItem("store_id");
				localStorage.setItem("home-search", this.searchqry);
				this.$router.push({ path: `/shopdetail?storeid=${this.store_id.toString()}` });
			},
			async searchAutoComplete(){
				this.auto_complete = [];
				this.search_autocomplete.search_query = this.searchqry;
				this.search_autocomplete.store_id = localStorage.getItem("store_id");
				this.search_autocomplete.limit = 10;
				this.search_autocomplete.page = 1;
				await this.$store.dispatch("searchautocomplete", this.search_autocomplete).then(response => {
					this.auto_complete_result = response.data;
					this.auto_complete = this.auto_complete_result.filter(data => {
						return data.item_name.toLowerCase();
					});
					if(response.status == 0){
						this.auto_complete = [];
					}
				});
			},
		}
	};
</script>
<template>  
  <section class="advertising py-0 mid-banner-carousel" style="margin-bottom:15px;" :class="iscustomtitleflag == 0 ? 'delete_tag' : ''" v-if="propdata!=null">
    <div class="container-fluid px-0">
      <div class="container">
      <h2 class="section-heading mb-2" v-if="iscustomtitleflag == 1">{{ iscustomtitle }}</h2>
      <h2 class="section-heading mb-2" v-else></h2>
      </div>
      <b-carousel
        id="carousel-1"
        :interval="20000"
        controls
        indicators
      >
        <b-carousel-slide v-for="(banner,index) in mobileBanners" v-bind:key="index" :interval="banner.transition_time">
          <template #img>
            <v-lazy-image
              class="d-block img-fluid w-100"
              :src="banner.banner"
              src-placeholder="banner.banner"
              :alt="banner.banner_alt_text"
              @click="bannerRedirect(banner)"
              v-bind:style="bannerBgImage(banner)"
            />
          </template>
        </b-carousel-slide>
      </b-carousel>
    </div>
  </section>
</template>
<script>
import VLazyImage from "v-lazy-image/v2";
export default {
  name: "MidBanner",
  components:{
    VLazyImage
  },
  props: {
    propdata: {
      type: Array
    },
    iscustomtitle:{
      type:String,
    },
    iscustomtitleflag:{
      type: String,
    }
  },
  data() {
    return {
      store_id: '1',
      category: {
        category_id: '',
        category_name: '',
      },
    }
  },
  computed: {
    mobileBanners() {
        return this.propdata;
    },
  },
  methods: {
    bannerBgImage(ban) {
      var result = '';
      if(ban.link_type != 0){
        result += 'cursor:pointer;'
      }
      return result;
    },
    bannerRedirect(banner_item){
      if(banner_item.link_type == 2 && banner_item.redirectweblink != null ){

        window.open(banner_item.redirectweblink, '_blank');

      } else if(banner_item.link_type == 1){

        if(banner_item.item_id != 0){
          this.$router.push({ path: `/productdetail?itemid=${banner_item.item_id.toString()}` });
        } else if(banner_item.store_id != 0 && banner_item.category_id != 0){
          
          this.category.category_id = banner_item.category_id;
          this.category.category_name = (banner_item.category_name != undefined) ? banner_item.category_name : 'Combo';
          localStorage.setItem("selected-category", JSON.stringify(this.category));
          this.$router.push({ path: `/shopdetail?storeid=${banner_item.store_id.toString()}` });
        } else if(banner_item.store_id != 0){
          this.$router.push({ path: `/shopdetail?storeid=${banner_item.store_id.toString()}` });
        }

      }
    }
  }
};
</script>
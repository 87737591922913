<template>
  <!-- order section -->
  <section class="offer" v-if="propdata.length > 0">
    <div class="container">
      <h2 class="section-heading" v-if="iscustomtitleflag == 1">
        {{ iscustomtitle }}
      </h2>
      <h2 class="section-heading" v-else></h2>
      <div :class="(is_business_model!=3) ? 'carousel-wrap ' : 'carousel-wrap b2c-offer hideshowlogic'">
        <carousel
          :items="3"
          :margin="25"
          :autoWidth="false"
          :responsive="{0:{items:propdata.length == 1 ? 1 : 1.3,nav:true,loop:propdata.length > 2 ? true : false},992: {
              items: 2,
              nav:true
            },1200: {
              items: 3,
              margin: 25,
              nav:true
            }}"
          :navText="[initprevbutn,initnextbutn]"
        >
          <div class="item" :style="color_code_list[index]" v-for="(blckbstrofr,index) in propdata" :key="index">
            <div class="offer-box" >
              <div class="profile-id media" :class="is_business_model == 0 ? 'innerdivsection' : ''" v-if="is_business_model!=3">
                <router-link :to="'/shopdetail?storeid='+blckbstrofr.store_id" :title="blckbstrofr.store_name">
                  <img
                    :src="blckbstrofr.store_logo"
                    class="mr-3 rounded-circle"
                    alt="..."
                    height="100"
                    width="10"
                    v-if="blckbstrofr.store_logo!=''"
                  >
                  <avatar
                    :fullname="blckbstrofr.store_name.split(' ')[0]"
                    :size="96"
                    v-if="blckbstrofr.store_logo==''"
                    class="mr-3 rounded-circle"
                  ></avatar>
                </router-link>
                <div class="media-body">
                  <router-link :to="'/shopdetail?storeid='+blckbstrofr.store_id" :title="blckbstrofr.store_name" style="color:white;"><h5>{{blckbstrofr.store_name | strippedOfferTitle}}</h5> </router-link>
                  <!-- <a href="javascript:void(0)" @click="getStoreByCategory(blckbstrofr)" :title="blckbstrofr.category_name" style="color:white;display: inline;">
                    <h6>{{blckbstrofr.category_name}}</h6>
                  </a> -->
                  <a class="location" href="javascript:void(0)">
                    <img src="../../assets/images/location-icon.png" alt="location-icon.png">
                    <span v-if="blckbstrofr.area_name">{{blckbstrofr.area_name | strippedOfferTitle}}</span>
                    <span v-if="blckbstrofr.city_name">, {{blckbstrofr.city_name}}</span>
                  </a>
                </div>
              </div>

              <div class="offer-description">
                <h4 :title="blckbstrofr.title">{{blckbstrofr.title | strippedOfferDescription}}</h4>
                <p>
                  Validity Period: {{blckbstrofr.start_date}}
                </p>
                <p v-if="blckbstrofr.minimum_value != null" :title="blckbstrofr.minimum_value">
                  {{blckbstrofr.minimum_value | strippedMinValue}}
                </p>
              </div>
              <div class="row mx-0 align-items-center">
                <div
                  class="promocode"
                  id="discountcode"
                  v-clipboard:copy="blckbstrofr.discount_code"
                  v-clipboard:success="clipboardSuccessHandler"
                  v-if="blckbstrofr.discount_code"
                  style="cursor:pointer"
                >{{blckbstrofr.discount_code}}</div>
                <input type="hidden" id="testing-code" :value="blckbstrofr.discount_code">
                <ul class="list-unstyled">
                  <li>
                    <a href="javascript:void(0)" @click="viewDetail(blckbstrofr)">View</a>
                  </li>
                  <li>
                    <a
                      style="cursor:pointer;"
                      @click="serviceviewlink(blckbstrofr.store_id)"
                    >Shop</a>
                  </li>
                </ul>
              </div>
              <div class="icon-bg">
                <img :src="blckbstrofr.web_offer_icon" alt="...">
              </div>
            </div>
          </div>
        </carousel>
      </div>
    </div>
    <OfferDetail></OfferDetail>
  </section>
</template>
<script>
import Avatar from "vue-avatar-component";
import carousel from "vue-owl-carousel";
import OfferDetail from "../offer/OfferDetail";
import { bus } from "../../main";
export default {
  data() {
    return {
      category: {
        shop_category_id: "",
        shop_category_name: "",
        type: "",
      },
      is_business_model: 0,
      initprevbutn:
        '<button type="button" role="presentation" class="owl-prev"><span aria-label="Previous">‹</span></button>',
      initnextbutn:
        '<button type="button" role="presentation" class="owl-next"><span aria-label="Next">›</span></button>',
      color_code_list:[]
    };
  },
  name: "BlockbusterOffer",
  components: {
    carousel,
    Avatar,
    OfferDetail
  },
  props: {
    propdata: {
      type: Array
    },
    iscustomtitle:{
      type:String,
    },
    iscustomtitleflag:{
      type: String,
    }
  },
  filters: {
    strippedOfferTitle: function(value) {
      if (value.length > 11) {
        return value.slice(0, 11) + "...";
      }
      return value;
    },
    strippedOfferDescription: function(value) {
      if (value.length > 36) {
        return value.slice(0, 36) + "...";
      }
      return value;
    },
    strippedMinValue: function(value) {
      if (value.length > 42) {
        return value.slice(0, 42) + "...";
      }
      return value;
    }
  },
  mounted(){
     this.is_business_model = localStorage.getItem("is_business_model");
      var offercolorcode;
      offercolorcode = localStorage.getItem("offer_color_code");
      if(offercolorcode != '' && offercolorcode != undefined){
      var globalsettingcolorcodelist = offercolorcode.split(',').length;
      var indexGlobalSettingColorCode = 0;
      for(var ofObj=0;ofObj < this.propdata.length;ofObj++){
         if(indexGlobalSettingColorCode >= globalsettingcolorcodelist)
         {
           indexGlobalSettingColorCode = 0;
         }
         this.color_code_list.push(' background-color:'+offercolorcode.split(',')[indexGlobalSettingColorCode]+' !important')
         indexGlobalSettingColorCode = indexGlobalSettingColorCode + 1;
      }
      }
  },
  methods: {
    isMobile(){
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true;
      } else {
        return false;
      }
    },
    getStoreByCategory(category){
      document.body.classList.remove('top-menu-add-padding');
      if(this.is_business_model == 3){
        return true;
      } else {
        this.category.shop_category_id = category.category_id;
        this.category.shop_category_name = category.category_name;
        this.category.type = "category";
        localStorage.setItem("selected-category", JSON.stringify(this.category));
        this.$router.push({ path: `/category?categoryid=${this.category.shop_category_id.toString()}` });
      }
    },
    serviceviewlink(storeid) {
      this.$router.push({ path: `/shopdetail?storeid=${storeid.toString()}` });
    },
    viewDetail(offrlist) {
      bus.$emit("offerdetail", offrlist);
      window.$("#Detailpopup").modal("show");
    },
    clipboardSuccessHandler () {
      this.$toast.success("Code copied to clipboard");
    },
  }
};
</script>
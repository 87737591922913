<template>
  <section class="popular-stores" :class="iscustomtitleflag == 0 ? 'delete_tag' : ''">
    <div class="container">
      <h2 class="section-heading" v-if="iscustomtitleflag == 1">{{ iscustomtitle }}</h2>
      <h2 class="section-heading" v-else></h2>
      <store-block :storedata="propdata"></store-block>
    </div>
  </section>
</template>
<script>
import StoreBlock from "../store/StoreBlock";
export default {
  name: 'PopularStore',
  props:{
    propdata:{
      type:Array,
    },
    iscustomtitle:{
      type:String,
    },
    iscustomtitleflag:{
      type: String,
    }
  },
  components:{
    "store-block": StoreBlock,
  }
}
</script>
<template>
  <div class="container" :class="iscustomtitleflag == 0 ? 'delete_tag' : ''">
    <div class="my-orders order-call-store" v-if="loading == false">
      <h2 class="section-heading mb-2" v-if="iscustomtitleflag == 1">
        {{ iscustomtitle }}
      </h2>
      <span v-if="getcustorder!=null">
        <div class="row" v-if="route_name!='home'">
        <form class="shop-searchbar col-lg-6 d-flex flex-wrap align-items-center mb-2" @submit.prevent="myordersearch">
          <div class="form-group mb-2 col-12">
            <label for="searchbar" class="sr-only">search</label>
            <input
              type="text"
              class="form-control"
              id="searchproduct"
              name="searchproduct"
              v-model="order_search_query"
              placeholder="Search for order"
              @keyup = "myordersearch"
            >
          </div>
          <a href="javascript:void(0)" class="btn btn-clear" v-show="order_search_query != '' || getcustorder.length <=0" @click="myorderclear()">X</a>
          <a href="javascript:void(0)" class="btn btn-primary" @click="myordersearch()"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" class="_1GTCc" viewBox="5 -1 12 25" height="24" width="24" fill="#23c7fa">
            <path d="M17.6671481,17.1391632 L22.7253317,22.1973467 L20.9226784,24 L15.7041226,18.7814442 C14.1158488,19.8024478 12.225761,20.3946935 10.1973467,20.3946935 C4.56550765,20.3946935 0,15.8291858 0,10.1973467 C0,4.56550765 4.56550765,0 10.1973467,0 C15.8291858,0 20.3946935,4.56550765 20.3946935,10.1973467 C20.3946935,12.8789625 19.3595949,15.3188181 17.6671481,17.1391632 Z M10.1973467,17.8453568 C14.4212261,17.8453568 17.8453568,14.4212261 17.8453568,10.1973467 C17.8453568,5.97346742 14.4212261,2.54933669 10.1973467,2.54933669 C5.97346742,2.54933669 2.54933669,5.97346742 2.54933669,10.1973467 C2.54933669,14.4212261 5.97346742,17.8453568 10.1973467,17.8453568 Z"/>
          </svg></a>
        </form>
        
        <p class="text-centerte text-lg-right prevNextMyOrder col-lg-6 arrow-prevNextMyOrder" v-if="getcustorderstatus!=0">
          <button :disabled="pageNumber === 1" @click="prevPage" class="btn"><img src="../../assets/images/pre-slider-btn.png"></button>
          &nbsp;
          <button
            :disabled="pageNumber == pageCount"           
            @click="nextPage"
            class="btn"
          ><img class="next-page-btn" src="../../assets/images/pre-slider-btn.png"></button>
        </p>

        </div>
        <div class="row order-box-row" v-if="getcustorderstatus!=0">
          <div class="col-lg-6" v-for="(order,index) in getcustorder" v-bind:key="index">
            <div class="order-box">
              <div class="profile-id">
                <div class="media">
                  <div class="order-list-user">
                  <div class="ordered-profile  mr-3">
                    <router-link :to="'/shopdetail?storeid='+order.store_id">
                      <img
                        :src="order.store_logo"
                        alt="..."
                        v-if="order.store_logo!=''"
                        class="rounded-circle"
                      >
                      <!-- <ul>
                        <li>
                          <a :href="'tel:'+order.phone">
                            <img src="../../assets/images/svgs/call-green.svg">
                          </a>
                        </li>
                      </ul> -->
                      <avatar
                        :fullname="order.store_name.split(' ')[0]"
                        :size="66"
                        v-if="order.store_logo==''"
                        class="rounded-circle"
                      ></avatar>
                    </router-link>
                   
                  </div>
                   <a :href="'tel:'+order.phone" class="my-order-call-icon">
                      <img src="../../assets/images/svgs/call-green.svg">
                    </a>
                  </div>
                  <div class="media-body">
                    <div class="row form-row">
                      <div class="col-sm-7 order-description">
                        <router-link :to="'/shopdetail?storeid='+order.store_id" style="color: #000;">
                          <h5>{{order.store_name}}</h5>
                        </router-link>
                        <!-- <a href="javascript:void(0)" class="d-block" @click="getStoreByCategory(order)" :title="order.category_name">
                          <h6>{{order.category_name}}</h6>
                        </a> -->
                        <a class="location" href="#">
                          <img src="../../assets/images/location-icon-gray.png" alt="location-icon.png">&nbsp;
                          <!-- <span v-if="order.area_name">{{order.area_name}}</span> -->
                          <span v-if="order.city_name"> {{order.city_name}}</span>
                          <span v-if="order.state_name">, {{order.state_name}}</span>
                        </a>
                      </div>
                      <div
                        class="col-sm-5 delivery-description text-sm-right mt-3 mt-sm-0 flex-md-column flex-row justify-content-between"
                        v-if="order.order_status!=''"
                      >
                        <h5 v-if="order.order_status==1">Pending</h5>
                        <h5 v-if="order.order_status==2">Accepted</h5>
                        <h5 v-if="order.order_status==3">Ready For Pickup</h5>
                        <h5 v-if="order.order_status==4" class="delivery-label">Out for Delivery</h5>
                        <h5 v-if="order.order_status==5">Delivered</h5>
                        <h5 v-if="order.order_status==6">Order Rejected</h5>
                        <h5 v-if="order.order_status==7">Undelivered</h5>
                        <h5 v-if="order.order_status==8">Cancelled</h5>
                        <h5 v-if="order.order_status==9">In-Process</h5>

                        <h6 v-if="order.order_status==4">
                          <img src="../../assets/images/helmet.png" alt="helmet">
                          {{order.deliveryboy_name}}
                        </h6>
                        <h6 v-if="order.delivery_type == 1">
                          <img src="../../assets/images/svgs/delievery.svg" alt="helmet" width="20" height="20">
                          Delivery
                        </h6>
                        <h6 v-if="order.delivery_type == 2">
                          <img src="../../assets/images/vegitables-icon.png" alt="helmet" width="15" height="20">
                          Pickup
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="order-details">
                <ul class="detils-listing list-unstyled mt-3">
                  <li class="d-flex justify-content-between align-items-center">
                    <div class="mr-2">
                      <h5>Order Number</h5>
                      <h6>{{order.order_no}}</h6>
                    </div>
                    <div class="d-flex align-itms-center">
                      <a href="javascript:void(0)" class="order-inquiry" @click="contact(order.order_no, order.khata_date)" data-toggle="modal" data-target="#ContactUsModal">
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                          <g>
                            <g>
                              <circle class="st0" cx="50" cy="50" r="50"/>
                            </g>
                            <g>
                              <path class="st1" d="M49.5,75c-0.9-0.2-1.8-0.5-2.5-1.2c-1.3-1.2-1.7-2.7-1.3-4.4c0.5-1.8,1.7-2.9,3.5-3.1
                                c2.1-0.3,3.8,0.6,4.7,2.6c0.1,0.2,0.2,0.3,0.5,0.3c1.4,0,2.8,0,4.2,0c2,0,3.6-1.2,4.3-3.1c-0.8,0.2-1.6,0.3-2.4,0
                                c-2-0.6-3.2-2.1-3.3-4.2c0-4-0.1-7.9,0-11.9c0.1-3.5,3.7-5.5,6.7-3.7c1.4,0.8,2.1,2.1,2.1,3.7c0,5,0.1,10.1,0,15.1
                                c0,3.8-3.2,6.9-7,7c-1.5,0-3.1,0-4.6,0c-0.2,0-0.4,0-0.5,0.3c-0.7,1.4-1.8,2.3-3.4,2.6c0,0,0,0-0.1,0.1C50.2,75,49.9,75,49.5,75z"
                                />
                              <path class="st1" d="M28,47c0-12.2,10.1-22.4,22.7-22C63,25.4,72,35.6,71.9,47c-0.6-0.4-1.2-0.8-1.9-1c-0.1,0-0.2-0.1-0.2-0.1
                                c-0.8,0-0.9-0.6-1-1.3c-0.4-3.4-1.6-6.4-3.6-9.1c-2-2.7-4.6-4.8-7.8-6.1c-2.6-1.1-5.3-1.6-8.2-1.5c-3.3,0.1-6.4,1.1-9.3,2.8
                                c-3.3,2-5.7,4.8-7.3,8.3c-0.9,2-1.4,4.1-1.6,6.2c0,0.2,0,0.3-0.2,0.4C29.8,45.9,28.9,46.4,28,47z"/>
                              <path class="st1" d="M33.9,55.8c0-1.9,0-3.8,0-5.8c0-2,1.2-3.7,2.9-4.3c2.9-0.9,5.8,1,5.8,4.1c0.1,4,0.1,8,0,12
                                c-0.1,3.5-3.7,5.4-6.7,3.7c-1.5-0.9-2.1-2.2-2.1-3.9C33.9,59.7,33.9,57.8,33.9,55.8z"/>
                              <path class="st1" d="M31,63c-1.5-0.6-2.5-1.7-2.9-3.3c-0.1-0.3-0.1-0.6-0.1-1c0-2,0-3.9,0-5.9c0-1.9,1.1-3.5,2.8-4.2
                                c0.1,0,0.1,0,0.2,0C31,53.5,31,58.2,31,63z"/>
                              <path class="st1" d="M69,63c0-4.8,0-9.5,0-14.3c1.5,0.4,2.9,2,2.9,3.7c0.1,2.3,0.1,4.6,0,6.8C71.8,61.1,70.7,62.3,69,63z"/>
                            </g>
                          </g>
                        </svg>
                        
                      </a>
                      <a href="javascript:void(0)" class="view-chat-icon chat-icons" v-if="is_chat_active == 1 && order.is_group_available == 1" @click="orderChat(order.order_id)">
                        <svg id="ChatIcon" xmlns="http://www.w3.org/2000/svg" width="21.906" height="16" viewBox="0 0 21.906 16">
                          <g id="message" transform="translate(-1974.185 -1096.78)">
                            <path id="Path_41" data-name="Path 41" d="M1990.478,1108.245a5.734,5.734,0,0,0,0-11.465H1979.8a5.743,5.743,0,0,0-.872,11.4v3.73a.873.873,0,0,0,1.523.58l3.78-4.242Zm-.023-6.813a1.081,1.081,0,1,1-1.08,1.081A1.081,1.081,0,0,1,1990.455,1101.432Zm-10.635,2.161a1.081,1.081,0,1,1,1.081-1.08A1.08,1.08,0,0,1,1979.82,1103.593Zm5.318-2.161a1.081,1.081,0,1,1-1.081,1.081A1.081,1.081,0,0,1,1985.138,1101.432Z" fill="#fff"/>
                          </g>
                        </svg>
                      </a>
                    </div>
                  </li>
                  <li>
                    <h5>Order On</h5>
                    <h6>
                      {{order.khata_date}}
                    </h6>
                  </li>
                  <li>
                    <h5>Order Amount</h5>
                    <h6><span v-html="priceFormatter(order.amount)"></span></h6>
                  </li>
                </ul>
                <div class="button row mx-0">
                  <a
                    href="javascript:void(0)"
                    class="btn left-button re-ordered"
                    :style="cancelReOrderButton"
                    @click="cancelOrder(order.order_id, order.order_no)"
                    v-if="order.order_status==1 && order.allow_cancellation == 1"
                  >Cancel Order</a>
                  <a
                    href="javascript:void(0)"
                    class="btn left-button re-ordered"
                    :style="cancelReOrderButton"
                    @click="reorder(order.store_id, order.order_id)"
                    v-if="(order.order_status==6 || order.order_status==5) && order.is_subscription_expire == 0"
                  >Re-Order Now</a>
                  <a
                    href="javascript:void(0)"
                    :style="colorObj"
                    class="btn btn-yellow view-details"
                    @click="getOrderDetails(order.order_id)"
                  >View Details</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p class="text-center" v-if="getcustorderstatus!=0">
        <button :disabled="pageNumber === 1" @click="prevPage" :style="colorObj" class="btn button-style hoverEffect">Previous</button>
          &nbsp;
          <button
            :disabled="pageNumber == pageCount"
            :style="colorObj"
            @click="nextPage"
            class="btn button-style hoverEffect"
          >Next</button>
        </p>
      </span>
      <div class="basket-order my-order-tab col-lg-12 mt-4 mt-lg-0" v-if="getcustorderstatus == 0">
          <div class="table-responsive basket-list">
            <div class="empty-box text-center">
              <div class="contents">
                <div class="empty-image">
                  <img src="../../assets/images/profile-icons/my-order.svg" alt="empty-box">
                </div>
              <h3>No Records Found</h3>
              </div>
            </div>
          </div>
        </div>
      <OrderDetail :orderid="order_id" :chat_active="is_chat_active"></OrderDetail>
    </div>
    <div
              class="modal fade"
              id="ReplaceCartModal"
              tabindex="-1"
              role="dialog"
              aria-labelledby="ReplaceCartModalLabel"
              aria-hidden="true"
            >
            <input type="hidden" id="currentStoreId"/>
            <input type="hidden" id="currentOrderId"/>
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="ReplaceCartModalLabel">Replace cart item(s)?</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">Your cart already contain items.Do you want to discard the selection and add items from current store?</div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
                    <button type="button" :style="colorObj" class="btn repeat-btn hoverEffect" @click="replaceItems()" >Yes</button>
                  </div>
                </div>
              </div>
            </div>
            
    <div class="container" v-if="loading==true">
      <p class="text-center">
        <img src="../../assets/images/loading.gif" />
      </p>
    </div>
    <CancelOrder></CancelOrder>
  </div>
</template>

<script>
import { bus } from "../../main";
import moment from "moment-timezone";
import Avatar from "vue-avatar-component";
import OrderDetail from "../order/OrderDetail";
import CancelOrder from "../order/CancelOrder";
import helper from '../../store/helper';
import localconfig from "../../global_settings.json"
export default {
  name: "Myorder",
  props: {
    iscustomtitle:{
      type:String,
    },
    iscustomtitleflag:{
      type: String,
    }
  },
  components: {
    Avatar,
    OrderDetail,
    CancelOrder,
  },
  mixins: [helper],
  data() {
    return {
      openModal:false,
      loading: true,
      currency_symbol: "₹",
      userprofile: null,
      selected_status: "",
      orderbody: {
        user_id: "",
        search_query:"",
        page:1,
        limit:1
      },
      orderdetail: {
        order_id: ""
      },
      custmrreordrdelvry: {
        store_id: "",
        order_id: "",
        user_id: "",
        cart_type: "1",
        remove_cart: "0"
      },
      pageNumber: 1,
      pageOfItems: [],
      getcustorder: null,
      getcustorderstatus:'',
      getcustordercount:0,
      currentPage: 1,
      size: 4,
      list_size:1,
      order_id: 0,
      category: {
        shop_category_id: "",
        shop_category_name: "",
        type: "",
      },
      is_chat_active: 0,
      order_search_query:"",
      orderListClone: null,
      show_price_same_font:1
    };
  },
  filters: {
    formatDate: function(date){
      var dateformat = (localStorage.getItem("date_format")!=null) ? localStorage.getItem("date_format") : localconfig.date_format_web;
      var utc_date = moment.utc(date, 'DD-MM-YYYY HH:mm:ss');
      if(dateformat == 'MM-DD-YYYY HH:mm:ss'){
        return moment(utc_date).format('MM-DD-YYYY HH:mm:ss');
      }
      if(dateformat == 'DD-MM-YYYY HH:mm:ss'){
        return moment(utc_date).format('DD-MM-YYYY HH:mm:ss');
      }
    }
  },
  computed: {
    pageCount() {
      if(this.getcustorderstatus == 1){
        let l = this.getcustordercount,
            s = this.size;
        return Math.ceil(l / s);
      } else {
        return 0;
      }
      
    },
    paginatedData() {
      const start = this.pageNumber * this.size,
        end = start + this.size;
      if(this.getcustorder != null && this.getcustorder != undefined)
        return this.getcustorder.slice(start, end);
      else
        return []
    }
  },
  mounted() {
    if (localStorage.getItem("user") != null) {
      this.userprofile = JSON.parse(localStorage.getItem("user"));
    } else {
      this.userprofile = null;
    }
    this.currency_symbol = localStorage.getItem("currency_symbol") != null && localStorage.getItem("currency_symbol") != "null"?localStorage.getItem("currency_symbol"):"₹";
    this.$route.name == "home" ? this.size = 2 : this.size = 4;
    this.list_size = this.$route.name == "home" ? 2 : this.size;
    this.route_name = this.$route.name;
    this.getOrderList();
    this.originalColor()
    bus.$on("globalsetting",data => {
      if(Object.keys(data).length != 0){
        this.show_price_same_font = JSON.parse(data).show_price_same_font;
        this.is_chat_active = JSON.parse(data).chat_module;
      }
    });
  },
  updated() {
    window.$(document).ready(function(){
      window.$("a.view-chat-icon").css("background-color", localStorage.getItem("button_default_color"))
      window.$('svg._1GTCc').css({ fill: localStorage.getItem("button_default_color")})
      window.$('svg g.listviewactive path').css({ fill: localStorage.getItem("button_default_color")})
      window.$('svg g.gridviewactive path').css({ fill: localStorage.getItem("button_default_color")})
      window.$('.clear_filter_search svg g path.st0').css({ fill: localStorage.getItem("button_default_color")})
      window.$('.order-inquiry svg circle.st0').css({ fill: localStorage.getItem("button_default_color")});
      window.$('.order-inquiry svg g path.st1').css({ fill: localStorage.getItem("css_button_customer_default_font")});
    })
  },
  methods: {
    contact(order_no,date){
      var obj = {};
      obj["order_no"] = order_no;
      obj["order_date"] = date;
      obj["from"] = "my-order";
      obj["inquiry_type"] = 2;
      bus.$emit("showContactForm", obj);
    },
    myorderclear(){
      this.order_search_query = '';
      this.getOrderList();
    },
    myordersearch(){
      this.getOrderList();
    },
    reInitFilter(){
      if(this.order_search_query != "")
      {      
        this.getcustorder = this.orderListClone;
        this.getcustorder = this.getcustorder.filter(x=>x.order_no.toLowerCase().indexOf(this.order_search_query.toLowerCase()) > -1 || x.store_name.toLowerCase().indexOf(this.order_search_query.toLowerCase()) > -1);
      }
    },
    priceFormatter(value) {
      if(value){
        var price = value.toString().split(".")[0];
        var decimal = value.toString().split(".")[1];
        var decimal_font_size = this.show_price_same_font;
        if(decimal_font_size == 1){
          return this.currency_symbol + price + "<span>." + decimal + "</span>";
        } else {
          return this.currency_symbol + price + "<span class='price-decimal'><small>." + decimal + "</small></span>";
        }
      } else {
        return '0';
      }
    },
    nextPage() {
      this.pageNumber++;
      this.getOrderList();
    },
    prevPage() {
      this.pageNumber--;
      this.getOrderList();
    },
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },
    getOrderList() {
      this.loading = this.order_search_query!=''? false: true;
      this.orderbody.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.orderbody.search_query = (this.order_search_query != "") ? this.order_search_query : "";
      this.orderbody.page = this.order_search_query != "" ? 1: this.pageNumber;
      this.orderbody.limit = this.list_size;
      this.$store
        .dispatch("getcustomerorderlist", this.orderbody)
        .then(response => {
          this.getcustorderstatus = response.status;
          this.getcustordercount = response.count;
          if (response.status != 0) {
            this.getcustorder = response.data;
            this.orderListClone = response.data;
          }
          this.loading = false; 
        });
    },
    getOrderDetails(orderid) {
      this.order_id = orderid;
      this.orderdetail.order_id = orderid;
      this.$store
        .dispatch("getcustomerorderdetail", this.orderdetail)
        .then(response => {
          bus.$emit("ShowOrderDetails", JSON.parse(JSON.stringify(response)));
          window.$("#order-details-popup").modal("show");
        });
    },
    replaceItems(){
      this.custmrreordrdelvry.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.custmrreordrdelvry.store_id = window.$('#currentStoreId').val();
      this.custmrreordrdelvry.order_id = window.$('#currentOrderId').val();
      this.custmrreordrdelvry.cart_type = 1;
      this.custmrreordrdelvry.remove_cart = 1;
      this.$store
        .dispatch("getcustomrreordr", this.custmrreordrdelvry)
        .then(response => {
          if(response.new_status == 0)
          {
             this.$toast.success(response.message);
          }
           else {
            if(response.message) 
               this.$toast.success(response.message);       
            this.$router.push("/cart");
           }
        });
       this.$router.push("/cart");
      window.$('#ReplaceCartModal').modal("hide");
    },
    reorder(storeid, orderid) {
      this.custmrreordrdelvry.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.custmrreordrdelvry.store_id = storeid;
      this.custmrreordrdelvry.order_id = orderid;
      this.custmrreordrdelvry.cart_type = 1;
      this.custmrreordrdelvry.remove_cart = 0;
      this.$store
        .dispatch("getcustomrreordr", this.custmrreordrdelvry)
        .then(response => {
          if(response.new_status == 0){
            this.$toast.error(response.message);
          } else {
            if(response.message == "Your cart already contains items. Do you want to discard the selection and add items from current store?") 
            {
               window.$('#ReplaceCartModal').modal("show");
               window.$('#currentStoreId').val(storeid);
               window.$('#currentOrderId').val(orderid);
            }
            else{
              this.$router.push("/cart");
            }
          }
        });
    },
    getStoreByCategory(category){
      if(localStorage.getItem("is_business_model")== 3){
        return true;
      } else {
        this.category.shop_category_id = category.category_id;
        this.category.shop_category_name = category.category_name;
        this.category.type = "category";
        localStorage.setItem("selected-category", JSON.stringify(this.category));
        this.$router.push({ path: `/category?categoryid=${this.category.shop_category_id.toString()}` });
      }
    },
    filterOrderByStatus(){
      return this.getcustorder.filter(function(item) {
        return item.order_status === 2
      });
    },
    cancelOrder(order_id, order_no){
      bus.$emit("cancel_order_id", order_id);
      bus.$emit("cancel_order_no", order_no);
      window.$("#OrderCancelModal").modal("show");
    },
    orderChat(order_id){
      this.$router.push({ path: `/chat?orderid=${order_id.toString()}` });
    }
  }
};
</script>
<template>
  <div class="store-block carousel-wrap">
    <carousel
      :items="5"
      :margin="25"
      :autoWidth="false"
      v-if="storedata.length"
      :responsive="{0:{items:storedata.length == 1 ? 1 : 1.3,nav:false,loop:storedata.length > 2 ? true : false},600:{items:2,nav:true},1200:{items:5,nav:true}}"
      :navText="[initprevbutn,initnextbutn]"
    >
      <div class="item" v-for="(store,index) in storedata" v-bind:key="index">
        <div class="shop-main">
          <router-link :to="'/shopdetail?storeid='+store.store_id" class="shop-image">
            <img v-if="store.store_logo!=''" v-bind:src="store.store_logo" alt="product">
            <avatar v-else :fullname="store.store_name.split(' ')[0]" :size="220" :radius="0" :color="avatarBackgroundColor"></avatar>
            <span v-if="store.is_open == 1" class="oder-status bg-green">Order Now</span>
            <span v-else class="oder-status bg-red">Closed Now</span>
            <span class="rating-status" v-if="(review_rating_module == 1) ? (store.avg_rating != null && show_review_rating == 1) : false">
              <img src="../../../assets/images/svgs/star-black.svg" alt="rating">
              <span>{{store.avg_rating}}</span>
            </span>
          </router-link>
          <div class="shop-details">
            <ul class="list-unstyled shop-catagory">
              <li class="category-sec">
                <!-- <a href="javascript:void(0)" @click="getStoreByCategory(store)"><img :src="store.category_black_icon" alt="store category"></a> -->
              </li>
              <li class="whislist" v-if="store.is_favourite == 1">
                <img src="../../../assets/images/svgs/whishlist-icon.svg" alt="favourite store">
              </li>
              <li class="khata" v-if="store.is_khata == 1">
                <img src="../../../assets/images/svgs/khata-icon.svg" alt="khata store">
              </li>
              <li class="wholesaler-icon" v-if="store.is_whole_seller == 1 && is_wholeseller == 1">
                <img src="../../../assets/images/svgs/ic_wholesaller.svg" alt="wholesaler tile">
              </li>
            </ul>
            <a :href="'/shopdetail?storeid='+store.store_id" :title="store.store_name"><h5>{{ store.store_name | strippedStorename }}</h5></a>
            <p class="distance">{{ store.distance }}</p> 
          </div>
        </div>
      </div>
    </carousel>
  </div>
</template>
<script>
import { bus } from "../../../main";
import helper from "../../../store/helper";
import Avatar from 'vue-avatar-component'
import carousel from 'vue-owl-carousel'
export default {
  name: 'StoreBlock',
  props:{
    storedata:{
      type:Array,
    },
  },
  components:{
    Avatar,
    carousel,
  },
  mixins:[helper],
  data() {
    return {
      initprevbutn:'<button type="button" role="presentation" class="owl-prev"><span aria-label="Previous">‹</span></button>',
      initnextbutn:'<button type="button" role="presentation" class="owl-next"><span aria-label="Next">›</span></button>',
      category: {
        shop_category_id: "",
        shop_category_name: "",
      },
      is_wholeseller:'',
      show_review_rating : 1,
      review_rating_module : 1,
      avatarBackgroundColor:"",
      avatarTextColor:"",     
    }
  },
  mounted(){
    this.is_wholeseller = localStorage.getItem("is_wholeseller")
    bus.$on("globalsetting", data => {
      if(Object.keys(data).length != 0){
        this.review_rating_module = parseFloat(JSON.parse(data).review_rating_module);
        this.show_review_rating = parseFloat(JSON.parse(data).show_review_rating);
      }
    });
    this.avatarBackgroundColor = localStorage.getItem("button_hover_color");
    this.avatarTextColor = localStorage.getItem("css_button_customer_hover_font");
    window.$('.store-block .avatar table tbody tr td').css({ color: this.avatarTextColor});
    window.$('<style>.btn-normal:hover { color: '+localStorage.getItem("button_default_color")+' !important; }</style>').appendTo('body');
  },
  filters: {
    strippedStorename: function(value) {
      if (value.length > 17) {
        return value.slice(0, 14) + "...";
      }
      return value;
    },
  },
  methods: {
    getStoreByCategory(category){
      if(localStorage.getItem("is_business_model") == 3){
        return true;
      } else {
        this.category.shop_category_id = category.category_id;
        this.category.shop_category_name = category.category_name;
        this.category.type = "category";
        localStorage.setItem("selected-category", JSON.stringify(this.category));
        this.$router.push({ path: `/category?categoryid=${this.category.shop_category_id.toString()}` });
      }
    },
  }
}
</script>
<template>
  <div>
    <div v-if="isLoading==false">
      <Defaultlayout :userprofile="userprofile">
        <component v-for="(homeconfig,index) in homeconfiguration_data" :key="index" :is="getComponentNameByTitle(homeconfig.title)" :propdata="getPropsByComponentName(homeconfig.title)" :isactive="homeconfig != undefined && homeconfig.is_active" :iscustomtitleflag="homeconfig != undefined && homeconfig.is_custom_title_apply" :iscustomtitle="homeconfig.custom_title" :itemcollection_id="homeconfig.item_collection_id!=null ? homeconfig.item_collection_id : ''" />
        <gettheapp></gettheapp>
      </Defaultlayout>
    </div>
    <div class="newloading" v-if="isLoading==true">
      <p>
        <img src="../../assets/images/loading.gif">
      </p>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import {firebasega} from "../../store/store"
import Slider from "../home/Slider";
import Searchbar from "../search/Searchbar";
import OrderSection from "../order/OrderSection";
import BlockbusterOffer from "../home/BlockbusterOffer";
import SponseredStores from "../home/store/SponseredStores";
import NearByStores from "../home/store/NearByStores";
import WholeSellerStores from "../home/store/WholeSellerStores";
import FavouriteStores from "../home/store/FavouriteStores";
import BestSeller from "../home/bestseller/BestSeller";
import Gettheapp from "../home/Gettheapp";
import Shopbycategory from "../home/Shopbycategory";
import CousinesOrder from "../home/CousinesOrder";
import PopularStores from "../home/store/PopularStores";
import TopPurchase from "../home/TopPurchase";
import Defaultlayout from "../../layouts/Defaultlayout";
import MidBanner from "../home/MidBanner"
import ItemCollection from "../home/ItemCollection"
import Myorder from "../order/Myorder"
import "owl.carousel";
import { bus } from "../../main";
import TopMenu from '../TopMenu.vue';
export default {
  name: "DefaultIndex",
  data() {
    return {
      storedetails: null,
      storebanners: null,
      storecategory: null,
      storesponsers: null,
      nearbystore: null,
      wholesellerstore:null,
      popularstore:null,
      favouritestore: null,
      storebestsellers: null,
      toppurchase: null,
      storebestoffers: null,
      middlebanners: null,
      previousorders: null,
      cuisines: null,
      getshopitemshopping: null,
      storehorizontalcategory:null,
      isLoading: true,
      userprofile: null,
      store_id: 1,
      addtocart: {
        user_id: "",
        store_id: "",
        items: []
      },
      iosappurl:'',
      androidappurl:'',
      is_business_model:'0',
      homeconfiguration_data:null,
    };
  },
  computed: {
    ...mapGetters(["storewise_details"]),
    homeConfigCollectionTitle(){
      var home_config_title = [];
      var home_config_custom_title = [];
      if(this.homeconfiguration_data!=null){
        this.homeconfiguration_data.forEach(data => {
          if(data.item_collection_id!=null && data.item_collection_id > 0){
            home_config_title.push(data.title);
            home_config_custom_title.push(data.custom_title)
          }
        });
      }
      return home_config_title;
    },
  },
  components: {
    Defaultlayout,
    "slider": Slider,
    "searchbar": Searchbar,
    "order-section": OrderSection,
    "blockbuster-offer": BlockbusterOffer,
    "sponsered-stores": SponseredStores,
    "nearby-stores": NearByStores,
    "wholeseller-store":WholeSellerStores,
    "popular-store" :PopularStores,
    "favourite-stores": FavouriteStores,
    "best-seller": BestSeller,
    "shopbycategory": Shopbycategory,
    "cousines-order": CousinesOrder,
    "top-purchase": TopPurchase,
    "gettheapp": Gettheapp,
    "midbanner": MidBanner,
    "myorder": Myorder,
    "top-menu": TopMenu,
    "item-collection": ItemCollection
  },
  props: {
    banners: {
      type: Array
    }
  },
  mounted() {
    firebasega.logEvent('notification_received');
    let LastUrlBeforeLogin = localStorage.getItem("LastUrlBeforeLogin");
    if (LastUrlBeforeLogin != null && LastUrlBeforeLogin != undefined)
    {
      localStorage.removeItem("LastUrlBeforeLogin");
    }

    this.iosappurl = localStorage.getItem("customerappurlios");
    this.androidappurl = localStorage.getItem("customerappurlandroid");
    
    this.getCityWiseDashboard();
    
    bus.$on("changeIt", data => {
      this.userprofile = data;
      window.$("#login-form").modal("hide");
    });

    if (localStorage.getItem("user") != null) {
      this.userprofile = JSON.parse(localStorage.getItem("user"));
    } else {
      this.userprofile = null;
    }

    if (localStorage.getItem("user_cart") != null && localStorage.getItem("is_login") != null) {
      var user_cart = JSON.parse(localStorage.getItem("user_cart"));
      
      // Remove existing cart items and add items from local cart after login
      this.addtocart.user_id = (localStorage.getItem("user") != null)
          ? JSON.parse(localStorage.getItem("user")).user_id
          : "0";
      this.addtocart.store_id = user_cart[0].store_id;
      this.addtocart.items = JSON.stringify(user_cart);

      this.$store
        .dispatch("addtocart", this.addtocart)
        .then(() => {
            localStorage.removeItem("user_cart");
        });
    }

    bus.$on("logout", data => {
      localStorage.removeItem("user");
      localStorage.removeItem("searchqry");
      localStorage.removeItem("selected-category");
      localStorage.removeItem("mobilenumber");
      localStorage.removeItem("user_cart");
      localStorage.removeItem("default_category");
      localStorage.removeItem("is_login");
      localStorage.removeItem("delivery_pickup");
      localStorage.removeItem("password");
      localStorage.removeItem("used_addon_choice");
      localStorage.removeItem("used_combo");
      localStorage.removeItem("store_id");
      bus.$emit("getcartlength", 0);
      this.userprofile = data;
    });
  },
  methods: {
    getCityWiseDashboard() {
      this.isLoading = true;  
      var lat = '23.0421945', lng = '72.5402261';
      if(localStorage.getItem("location_details") != null){
        if(JSON.parse(localStorage.getItem("location_details")).lat != null && JSON.parse(localStorage.getItem("location_details")).lng != null){
            lat = JSON.parse(localStorage.getItem("location_details")).lat;
            lng = JSON.parse(localStorage.getItem("location_details")).lng;
        }
      }
      var prelogin_pincode = localStorage.getItem("config_customer_pincode");
      var prelogin_area_id = localStorage.getItem("config_area_id");
      var postlogin_pincode = localStorage.getItem("user")!=null ? JSON.parse(localStorage.getItem("user")).zipcode : '';
      var postlogin_area_id = localStorage.getItem("user")!=null ? JSON.parse(localStorage.getItem("user")).area_id : '';
      var customer_location_type = localStorage.getItem("customer_location_type");
      localStorage.setItem("is_business_model",3);
      
      let bannerbody = {
        user_id: (localStorage.getItem("user") != null) ? JSON.parse(localStorage.getItem("user")).user_id : "0",
        lat: lat,
        long: lng,
        is_demo_app: localStorage.getItem("is_business_model") == 3 ? 1 : 0,
        area_id: (this.userprofile != null) ? (customer_location_type == 2 && postlogin_area_id!=null? parseInt(postlogin_area_id):'') : (customer_location_type == 2 && prelogin_area_id!=null? parseInt(prelogin_area_id):''),
        pincode: (this.userprofile != null) ? (customer_location_type == 3 && postlogin_pincode!=null? parseInt(postlogin_pincode):'') : (customer_location_type == 3 && prelogin_pincode!=null? parseInt(prelogin_pincode):''),
        is_mobile:0
      };
      console.log(bannerbody);
      this.$store.dispatch("getBanners", bannerbody).then(response => {
        this.isLoading = false;
        this.storedetails = JSON.parse(JSON.stringify(response.data));
        bus.$emit('citi_wise_dashboard', this.storedetails) 
        if(this.storedetails.status == 1) {
          if(localStorage.getItem("is_business_model") == 3 && this.storedetails.data.customer_stores != null) {
            this.storebanners = this.storedetails.data.customer_stores[0].banners;
            this.storecategory = this.storedetails.data.category;
            this.storebestsellers = this.storedetails.data.customer_stores[0].best_seller_items;
            this.getshopitemshopping = this.storedetails.data.shop_categories;
            localStorage.setItem("store_categories", JSON.stringify(this.getshopitemshopping));
            this.middlebanners = this.storedetails.data.customer_stores[0].middle_banners;
            this.toppurchase = this.storedetails.data.customer_stores[0].top_products;
            this.storebestoffers = this.storedetails.data.customer_stores[0].offers;
            this.previousorders = this.storedetails.data.customer_stores[0].previous_orders;
            this.cuisines = this.storedetails.data.customer_stores[0].cuisines;
            this.store_id = this.storedetails.data.customer_stores[0].store_id;
            this.storehorizontalcategory = this.storedetails.data.shop_categories_horizontal;
            this.homeconfiguration_data = this.storedetails.data.home_configuration;
            localStorage.setItem("store_id", this.store_id);
            localStorage.setItem("whats_new_customer", this.storedetails.data.customer_stores[0].whats_new_customer);
            if(this.storehorizontalcategory == null){
              document.body.classList.add('top-menu-padding');
            } else {
              document.body.classList.remove('top-menu-padding');
              document.body.classList.add('top-menu-add-padding');
            }
          } else {
            this.storebanners = this.storedetails.data.banners;
            this.storecategory = this.storedetails.data.category;
            this.storesponsers = this.storedetails.data.sponsored_stores;
            this.nearbystore = this.storedetails.data.nearby;
            this.wholesellerstore = this.storedetails.data.whole_seller_stores;
            this.favouritestore = this.storedetails.data.stores;
            this.storebestsellers = this.storedetails.data.best_seller_items;
            this.storebestoffers = this.storedetails.data.offers;
            this.middlebanners = this.storedetails.data.middle_banners;
            this.getshopitemshopping = this.storedetails.data.shop_categories;
            localStorage.setItem("store_categories", JSON.stringify(this.getshopitemshopping));
            this.toppurchase = this.storedetails.data.top_products;
            this.popularstore = this.storedetails.data.popular_stores;
            this.previousorders = this.storedetails.data.previous_orders;
            this.cuisines = this.storedetails.data.cuisines;
            this.storehorizontalcategory = this.storedetails.data.shop_categories_horizontal;
            this.homeconfiguration_data = this.storedetails.data.home_configuration;
            if(this.storehorizontalcategory == null){
              document.body.classList.add('top-menu-padding');
            } else {
              document.body.classList.remove('top-menu-padding');
              document.body.classList.add('top-menu-add-padding');
            }
          }
          this.isLoading = false;
        }
        else{
          this.isLoading = false;
        }
      });
    },
    getComponentNameByTitle(title){
      if (title != undefined)
      {
      if(title === "banners"){
        return "slider"
      } else if(title === "sponsored_stores"){
        return "sponsered-stores"
      } else if(title === "category"){
        return "order-section"
      } else if(title === "stores"){
        return "favourite-stores"
      } else if(title === "whole_seller_stores"){
        return "wholeseller-store"
      } else if(title === "nearby"){
        return "nearby-stores"
      } else if(title === "best_seller_items"){
        return "best-seller"
      } else if(title === "shop_categories"){
        return "shopbycategory"
      } else if(title === "popular_stores"){
        return "popular-store"
      } else if(title === "cuisines"){
        return "cousines-order"
      } else if(title === "top_products"){
        return "top-purchase"
      } else if(title === "offers"){
        return "blockbuster-offer"
      } else if(title === "middle_banners"){
        return "midbanner"
      } else if(title === "previous_orders"){
        return "myorder"
      } else if(title === "shop_categories_horizontal"){
        return "top-menu"
      } else if(this.homeConfigCollectionTitle.includes(title)){
        return "item-collection";
      }
      }
    },
    getPropsByComponentName(title){
      if (title != undefined)
      {
        if(title === "banners"){
          if(this.storebanners!=null){
            return this.storebanners;
          }
        } else if(title === "sponsored_stores"){
          if(this.storesponsers!=null){
            return this.storesponsers;
          }
        } else if(title === "category"){
          if(this.storecategory!=null){
            return this.storecategory;
          }
        } else if(title === "stores"){
          if(this.favouritestore!=null){
            return this.favouritestore;
          }
        } else if(title === "whole_seller_stores"){
          if(this.wholesellerstore!=null){
            return this.wholesellerstore;
          }
        } else if(title === "nearby"){
          if(this.nearbystore!=null){
            return this.nearbystore;
          }
        } else if(title === "best_seller_items"){
          if(this.storebestsellers!=null){
            return this.storebestsellers;
          }
        } else if(title === "shop_categories"){
          if(this.getshopitemshopping!=null){
            return this.getshopitemshopping;
          }
        } else if(title === "popular_stores"){
          if(this.popularstore!=null){
            return this.popularstore;
          }
        } else if(title === "cuisines"){
          if(this.cuisines!=null){
            return this.cuisines;
          }
        } else if(title === "top_products"){
          if(this.toppurchase!=null){
            return this.toppurchase;
          }
        } else if (title === "offers"){
          if(this.storebestoffers!=null){
            return this.storebestoffers;
          }
        } else if (title === "middle_banners"){
          if(this.middlebanners!=null){
            return this.middlebanners;
          }
        } else if (title === "shop_categories_horizontal"){
          if(this.storehorizontalcategory!=null){
            return this.storehorizontalcategory
          }
        }
        else if (this.homeConfigCollectionTitle.includes(title))
        {
            return this.storedetails.data.customer_stores[0][title];
                    
        }
      }
    }
  },
};
</script>
<style>
.newloading {
  display: flex !important;
  height: 100% !important;
  align-items: center !important;
  justify-content: center !important;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
}
.carousel-caption{
  width: 100%;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  bottom: inherit;
  right: inherit;
}
.shop-details .shop-catagory li + li {
  margin-left: 6px;
}
@media (max-width: 767px) {
  .offer .owl-stage .item {padding: 10px;}
  .offer .media-body h5 {font-size: 14px;}
  .offer .offer-description h4, .offer .offer-description p {font-size: 10px; line-height: 1.2;}
  .sponsored-stores .shop-main {max-width: 100%;}
  .shop-main .shop-image .avatar {width: 100% !important;}
  .cuisine-section .item-box .cuisine-image img {width: 100% !important; height: 100% !important;}
  .product-main .top-box .product-image {height: 70px;}
  .product-main .product-rating {right: initial; left: 0px; top:8px;}
}
@media (max-width: 575px) {
  .carousel-wrap {margin-left: -15px;margin-right: -15px;}
  .offer  .carousel-wrap, .sponsored-stores .carousel-wrap{margin-left: 0; margin-right: 0;}
   footer .top-footer .footer-links, footer .top-footer h2{text-align: center;}
  footer .social-media-links {justify-content: center;}
}
</style>
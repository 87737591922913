<template>
    <div :style="buttonColor.backgroundColor!=null && buttonColor.backgroundColor!=undefined? 'background-color:'+buttonColor.backgroundColor : 'background-color:'+localconfig.css_button_customer_default" class="fullwidth overlap-slider d-none d-md-block" style="margin-top:21px;">
        <div class="container">
            <div class="nav-scroller-wrapper">
                <nav class="nav-scroller">
                    <ul class="nav-scroller-content d-flex list-unstyled">
                    <li v-for="(category,index) in propdata" :key="index" :class="category.sub_categories.length > 1 ? 'dropdown' : ''" @mouseout="hideMenuList(category)" @mouseover="showMenuList(category)">
                        <a :style="colorObj" v-if="category.sub_categories.length!=1" href="javascript:void(0);" @click="getStoreByCategory(category)" @mouseover="categoryHoverColor(index)" class="nav-scroller-item">{{category.shop_category_name}}</a>
                        <a :style="colorObj" v-else href="javascript:void(0);" @click="getStoreByCategory(category)" @mouseover="categoryHoverColor(index)" class="nav-scroller-item">{{category.shop_category_name}}</a>                        
                        <div class="submenu-border" v-if="category.sub_categories.length > 1">
                            <ul class="submenu list-unstyled">
                                <li>
                                    <div class="row">
                                    <div class="col-md-9">
                                        <div class="submenu-wrap">
                                            <h3>{{category.shop_category_name}}</h3>
                                            <ul class="row list-unstyled submenu-item" v-if="is_business_model == 3">
                                                <li  v-for="(subcategories,index) in category.sub_categories.filter(x => x.items_count > 0)" :key="index">
                                                    <a
                                                        :style="previousIndex == index ? addButtonColor : ''" 
                                                        @mouseover="subCategoryHoverColor(index)" 
                                                        @mouseout="subCategoryHoverOutColor(index)"
                                                        href="javascript:void(0);" 
                                                        @click="getStoreBySubCategory(category,subcategories)" 
                                                        :title="subcategories.shop_category_name">
                                                        {{subcategories.shop_category_name}}
                                                    </a>
                                                </li>
                                            </ul>
                                            <ul class="row list-unstyled submenu-item" v-else>
                                                <li  v-for="(subcategories,index) in category.sub_categories" :key="index">
                                                    <a
                                                        :style="previousIndex == index ? addButtonColor : ''" 
                                                        @mouseover="subCategoryHoverColor(index)" 
                                                        @mouseout="subCategoryHoverOutColor(index)"
                                                        href="javascript:void(0);" 
                                                        @click="getStoreBySubCategory(category,subcategories)" 
                                                        :title="subcategories.shop_category_name">
                                                        {{subcategories.shop_category_name}}
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="sub-menu-img">
                                        <img :src="category.category_image" class="w-100">
                                        </div>
                                    </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </li> 
                    </ul>
                </nav>
                <button class="btn btn-sm btn-primary nav-scroller-btn nav-scroller-btn--left" :class="content.contentLength > 0 ? 'active' : ''" @click="scrollLeft()">  
                    <span class="previous-arrow"></span>
                </button>    
                <button class="btn btn-sm btn-primary nav-scroller-btn nav-scroller-btn--right" :class="propdata != undefined && propdata.length > 7 ? 'active': ''" @click="scrollRight()">
                    <span class="next-arrow"></span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import {bus} from "../main";
import helper from "../store/helper";
import localconfig from "../global_settings.json"
export default {
    name:'TopMenu',
    props:{
        propdata: Array
    },
    mixins:[helper],
    data(){
        return{
            is_business_model:0,
            category:{
                category_id: "",
                category_name:""
            },
            store_id: '1',
            subcategory:{
                category_id: "",
                category_name: "",
                subcategory_id: "",
                subcategory_name:""
            },
            previousIndex: 0,
            previousCategoryIndex:0,
            categoryLinkColor:{
                color:""
            },
            content:{
                contentLength : 0
            },
            navbarBackground : {
                backgroundColor:''
            }
        }
    },
    mounted() { 
        bus.$on("globalsetting",data => {
        if(Object.keys(data).length != 0){
            this.is_business_model = JSON.parse(data).business_model;
        } else {
            this.is_business_model = localconfig.business_model;
        }
        });
        this.buttonOriginalColor();
        this.localconfig = localconfig
    },
    methods: {
        showMenuList(category){
            category.sub_categories.filter(x => x.items_count > 0).length > 1
            if(this.is_business_model == 3) {
                if(category.sub_categories.filter(x=>x.items_count).length > 1){
                    window.$('.nav-scroller-wrapper').addClass('nav-scroll-menu')
                    window.$('.submenu-border').addClass('submenu-end-border')
                }
            } else {
                window.$('.nav-scroller-wrapper').addClass('nav-scroll-menu')
                window.$('.submenu-border').addClass('submenu-end-border')
            }
        },
        hideMenuList(category){
            if(this.is_business_model == 3) {
                if(category.sub_categories.filter(x=>x.items_count).length > 1){
                    window.$('.nav-scroller-wrapper').removeClass('nav-scroll-menu')
                    window.$('.submenu-border').removeClass('submenu-end-border')
                }
            } else {
                window.$('.nav-scroller-wrapper').removeClass('nav-scroll-menu')
                window.$('.submenu-border').removeClass('submenu-end-border')
            }
        },
        getStoreByCategory(category){
            document.body.classList.remove('top-menu-add-padding');
            if(this.is_business_model == 3) {
                this.category.category_id = category.store.category_id;
                this.category.category_name = category.shop_category_name;
                localStorage.setItem("selected-category", JSON.stringify(this.category));
                this.store_id = localStorage.getItem("store_id");
                this.$router.push({ path: `/shopdetail?storeid=${this.store_id.toString()}` });
            } else {
                category.type = "category";
                localStorage.setItem("category-id",category.shop_category_id);
                this.$router.push({ path: `/search-category` });
            }
        },
        getStoreBySubCategory(category,subcategory){
            document.body.classList.remove('top-menu-add-padding');
            if(this.is_business_model == 3) {
                this.subcategory.category_id = category.store.category_id;
                this.subcategory.category_name = category.shop_category_name
                this.subcategory.subcategory_id = subcategory.store.category_id;
                this.subcategory.subcategory_name = subcategory.shop_category_name
                localStorage.setItem("selected-subcategory", JSON.stringify(this.subcategory));
                this.store_id = localStorage.getItem("store_id");
                this.$router.push({ path: `/shopdetail?storeid=${this.store_id.toString()}` });
            } else {
                this.getStoreByCategory(category);
            }
            
        },
        scrollRight(){ 
            this.content = document.querySelector('nav.nav-scroller')
            this.content.scrollLeft = (this.content.scrollLeft+50) + this.content.offsetLeft
            this.content.scrollLeft > 0 ? window.$('.nav-scroller-btn--left').addClass('active') : ''
            this.content.contentLength = this.content.scrollLeft; 
        },
        scrollLeft(){
            this.content.scrollLeft = (this.content.scrollLeft-50) - this.content.offsetLeft;      
            this.content.scrollLeft == 0 ? window.$('.nav-scroller-btn--left').removeClass('active') : ''
        },
        subCategoryHoverColor(index){
            this.previousIndex = index;
            this.addButtonColor.color = localStorage.getItem("button_default_color");
        },
        subCategoryHoverOutColor(index){
            this.previousIndex = index;
            this.addButtonColor.color = "#000";
        },
        categoryHoverColor(index){
            this.previousCategoryIndex = index;
            this.categoryLinkColor = "#fff"
        }
    }
   
}
</script>
<template>
  <section class="get-the-app">
    <div class="container">
      <div class="offer-bg">
        <div class="row ">
          <div class="col-md-8 col-xl-9">
            <div class="description">
             <h2>Get your Groceries Home-Delivered</h2>
            </div>
          </div>
          <div class="col-md-4 col-xl-3">
            <div class="logo">
              <img src="../../assets/images/logo.svg" alt="logo">
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>                          
export default {
  name: 'Gettheapp'
}
</script>
<template>
  <section class="nearby-stores" :class="iscustomtitleflag == 0 ? 'delete_tag' : ''">
    <div class="container">
      <div class="row">
        <h2 class="section-heading col-md-9" v-if="iscustomtitleflag == 1">{{ iscustomtitle }}</h2>
        <h2 class="section-heading col-md-9" v-else></h2>
        <div :class="[propdata.length <= 5 ? 'view-all-favoritestore nearbyviewall':'nearbyviewall', iscustomtitle != ''? 'view-all-fav-store': 'view-all-fav-store-wt']" class="col-12 button-row text-center col-md-3">
          <router-link href="javascript:void(0)" class="btn-normal" :to="{path: '/search#nearby', query: {title: iscustomtitleflag == 1 ? iscustomtitle: ''} }">View All</router-link>
        </div>
      </div>
      <store-block :storedata="propdata"></store-block>
    </div>
  </section>
</template>
<script>
import StoreBlock from "../store/StoreBlock";
export default {
  name: 'NearByStores',
  props:{
    propdata:{
      type:Array,
    },
    iscustomtitle:{
      type:String,
    },
    iscustomtitleflag:{
      type: String,
    }
  },
  components:{
    "store-block": StoreBlock,
  }
}
</script>
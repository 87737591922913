<template>
  <section class="cuisine-section" style="margin-bottom:0px;" :class="iscustomtitleflag == 0 ? 'delete_tag' : ''">
    <div class="container">
      <h2 class="section-heading" v-if="iscustomtitleflag == 1">
        {{ iscustomtitle }}
      </h2>
      <h2 class="section-heading" v-else>{{iscustomtitle}}</h2>
      <div class="carousel-wrap">
        <carousel
          :items="10"
          :margin="22"
          :autoWidth="false"
          
          :responsive="{0:{items:CousineDetails.length == 1?1: 1.3,nav:false,loop:CousineDetails.length >2 ?true:false},600:{items:2,nav:true},1200:{items:5,nav:true}}"
          :navText="[initprevbutn,initnextbutn]"
        >
        
          <div class="item" v-for="(shopcusines,index) in CousineDetails" :key="index">
            <div class="item-box">
              <a href="javascript:void(0)" @click="getStoreByCategory(shopcusines)" class="cuisine-image">
                <img :src="shopcusines.cuisine_image">
              </a>
              <a @click="getStoreByCategory(shopcusines)" >
                <h5 class="text-center" :title="shopcusines.cuisine_name">{{shopcusines.cuisine_name}}</h5>
              </a>
            </div>
          </div>
        </carousel>
      </div>
    </div>
  </section>
</template>
<script>
import carousel from "vue-owl-carousel";
export default {
  name: "CousinesOrder",
  props: {
    propdata: {
      type: Array
    },
    iscustomtitle:{
      type:String,
      default: 'Cuisine'
    },
    iscustomtitleflag:{
      type: String,
    }
  },
  components: {
    carousel
  },
  computed: {
    CousineDetails: function() {
      return this.propdata.filter(function(item) {
        return item.cuisine_image != "";
      });
    }
  },
  data() {
    return {
      initprevbutn:
        '<button type="button" role="presentation" class="owl-prev"><span aria-label="Previous">‹</span></button>',
      initnextbutn:
        '<button type="button" role="presentation" class="owl-next"><span aria-label="Next">›</span></button>',
      category: {
        shop_category_id: "",
        shop_category_name: "",
        type: "",
      },
      store_id: '1'
    };
  },
  methods: {
    getStoreByCategory(category){
      document.body.classList.remove('top-menu-add-padding');
      if(localStorage.getItem("is_business_model") == 3){
        return true;
      } else {
        this.category.shop_category_id = category.cuisine_id;
        this.category.shop_category_name = category.cuisine_name;
        this.category.type = 'cuisine';
        localStorage.setItem("selected-category", JSON.stringify(this.category));
        localStorage.setItem("category-id",this.category.shop_category_id);
        this.$router.push({ path: `/search-category` });
      }
    }
  }
};
</script>
<template>
  <div class="container-fluid p-0" style="margin-bottom:5px; margin-top:-2px;" :class="iscustomtitleflag == 0 ? '' : 'banner-title'">
    <div class="container">
      <h2 class="section-heading ml-0" v-if="iscustomtitleflag == 1">{{ iscustomtitle }}</h2>
    </div>
    <div id="carouselExampleCaptions" :class="(is_business_model == 3) ? 'carousel slide mb-2 home-page-slider' : 'carousel slide mb-2'" data-ride="carousel">
      <ol class="carousel-indicators">
        <li
          data-target="#carouselExampleCaptions"
          v-bind:data-slide-to="index"
          :class="(index==0) ? 'active':''"
          v-for="(ban1,index) in propdata"
          v-bind:key="index"
        ></li>
      </ol>
      <div class="carousel-inner">
          <div
            :class="(index==0) ? 'carousel-item active':'carousel-item'"
            v-for="(ban,index) in propdata"
            v-bind:key="index"
            v-bind:style="bannerBgImage(ban)"
            @click="bannerRedirect(ban)"
            :data-interval="ban.transition_time"
          >
          <div class="carousel-caption" v-if="is_business_model!=3">
            <div class="banner-text text-white">
              <h2 class="big-title" v-if="ban.banner_alt_text!=null">{{ban.banner_alt_text}}</h2>
            </div>
          </div>
          </div>
          <div class="carousel-caption" v-if="is_business_model == 3">
            <div class="container">
              <div class="banner-text text-center">
                <a href="javascript:void(0)" :style="colorObj.backgroundColor != null ? colorObj : 'background-color:'+localconfig.css_button_customer_default" class="btn order-now-b2c hoverEffect btn-OrderNow" @click="shopNowLink">Shop Now</a>
                <searchbar></searchbar>
              </div>
            </div>
          </div>
      </div>
      <a
        class="carousel-control-prev"
        href="#carouselExampleCaptions"
        role="button"
        data-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a
        class="carousel-control-next"
        href="#carouselExampleCaptions"
        role="button"
        data-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
    <searchbar v-if="is_business_model!=3"></searchbar>
  </div>
</template>
<script>
import {bus} from "../../main";
import SearchBar from '../search/Searchbar';
import helper from "../../store/helper";
import localconfig from "../../global_settings.json"
export default {
  components: { 
    searchbar: SearchBar
  },
  name: "Slider",
  props: {
    propdata: {
      type: Array
    },
    iscustomtitle:{
      type:String,
    },
    iscustomtitleflag:{
      type: String,
    }
  },
  mixins:[helper],
  data() {
    return {
      default_category: {
        category_id: '',
        category_name: '',                      
      },
      store_id: '1',
      is_business_model: 0,
      localconfig: ""
    }
  },
  mounted() {
    bus.$on("globalsetting",data => {
      if(Object.keys(data).length != 0){
        this.is_business_model = JSON.parse(data).business_model;
      } else {
        this.is_business_model = localconfig.business_model;
      }
    });
    window.$(document).ready(function(){
      window.$('.carousel').carousel({
        interval: 20000
      });
    });
    this.localconfig = localconfig
    this.originalColor()
  },
  methods: {
    bannerBgImage(ban) {
      var result = '';
      result = 'background-image: url("' + ban.banner + '");';
      if(ban.link_type != 0){
        result += 'cursor:pointer;'
      }
      return result;
    },
    bannerRedirect(banner_item){
      if(banner_item.link_type == 2 && banner_item.redirectweblink != null ){
        window.open(banner_item.redirectweblink, '_blank');
      } else if(banner_item.link_type == 1) {
        if(banner_item.item_id != 0) {
          this.$router.push({ path: `/productdetail?itemid=${banner_item.item_id.toString()}` });
        } else if(banner_item.store_id != 0 && banner_item.category_id != 0) {
          this.default_category.category_id = banner_item.category_id;
          this.default_category.category_name = (banner_item.category_name != undefined) ? banner_item.category_name : 'Combo';
          localStorage.setItem("selected-category", JSON.stringify(this.default_category));
          this.$router.push({ path: `/shopdetail?storeid=${banner_item.store_id.toString()}` });
        } else if(banner_item.store_id != 0) {
          this.$router.push({ path: `/shopdetail?storeid=${banner_item.store_id.toString()}` });
        }
      }
    },
    shopNowLink(){
      this.store_id = localStorage.getItem("store_id");
      this.$router.push({ path: `/shopdetail?storeid=${this.store_id.toString()}` });
    },
  }
};
</script>
<style scoped>
  .btn-OrderNow{
    width: 150px;
  }
</style>
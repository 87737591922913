<template>
  <div>
    <div
      class="modal fade"
      id="Detailpopup"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <div class="modal-body">
            <h2 class="text-center">Offer Details</h2>
            <div class="offer-details" v-if="offerdetaillist!=''">
              <div class="media">
                <img :src="offerdetaillist.offer_image" class="mr-3" alt="offer" />
                <div class="media-body">
                  <h5 class="mt-0">{{offerdetaillist.title}}</h5>
                  <h6 class="calid-label-one">{{offerdetaillist.validity}}</h6>
                  <h6 class="calid-label-two">{{offerdetaillist.minimum_value}}</h6>
                  <span
                    :style="promocodeColor"
                    class="promocode mb-4"
                    style="cursor:pointer"
                    v-clipboard:copy="offerdetaillist.discount_code"
                    v-clipboard:success="clipboardSuccessHandler"
                    v-if="offerdetaillist.discount_code"
                  >
                    {{offerdetaillist.discount_code}}
                    <input
                      type="hidden"
                      id="testing-code"
                      :value="offerdetaillist.discount_code"
                    />
                  </span>
                  <span class="offerdetail" v-if="offerdetaillist.offer_details.length>0">
                    <p v-for="offer_detail in offerdetaillist.offer_details" v-bind:key="offer_detail">{{offer_detail}}</p>
                  </span>
                </div>
              </div>
            </div>
            <span v-if="offerdetaillist!=''">
              <div class="term-condition" v-if="offerdetaillist.terms.length>0">
                <h4 class="mb-3 mb-xl-4">Terms & Conditions</h4>
                <span v-if="offerdetaillist.terms.length>0">
                  <p v-for="term in offerdetaillist.terms" v-bind:key="term">{{term}}</p>
                </span>
              </div>
            </span>

            <div class="button-row text-center" v-if="currentRouteName!='shopdetail'">
              <a
                href="javascript:void(0);"
                :style="colorObj"
                class="btn button-style hoverEffect"
                @click="serviceviewlink(offerdetaillist.store_id)"
              >Shop Now</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { bus } from "../../main";
import helper from "../../store/helper"
export default {
  name: "OfferDetail",
  data() {
    return {
      offerdetaillist: []
    };
  },
  mixins:[helper],
  computed: {
    currentRouteName() {
      return this.$route.name;
    }
  },
  mounted() {
    bus.$on("offerdetail", data => {
      this.offerdetaillist = data;
    });
    this.originalColor();
  },
  methods: {
    serviceviewlink(storeid) {
      window.$("#Detailpopup").modal("hide");
      window.$("#available-offer").modal("hide");
      this.$router.push({ path: `/shopdetail?storeid=${storeid.toString()}` });
    },
    clipboardSuccessHandler () {
      this.$toast.success("Code copied to clipboard");
    },
  }
};
</script>
<template>
  <div
    class="modal fade"
    id="OrderCancelModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="OrderCancelModalLabel"
    aria-hidden="true"
    ref="cancelordermodal"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-body" v-show="cancel_order_loading==false && cancellation_reasons != null">
          <div class="bottom-section">
            <h2 class="section-heading text-center">Select Reason for Cancellation</h2>
            <div class="conformation-section">
              <form
                class="address-form form-horizontal animated-form"
                @submit.prevent="submitCancelOrder()"
                method="post"
              >
                <div class="form-row">
                  <div class="form-group col-md-12 active">
                  <div class="select-field">
                    <label :style="textcolor" for="edit_state_id" class="control-label">
                      Select Reason
                      <span class="helpmsg">*</span>
                    </label>
                    <select
                      id="reason_id"
                      name="reason_id"
                      class="form-control"
                      value="Select Reason"
                      v-model="reason_id"
                      v-validate="'required'"
                      :class="{error: errors.has('reason_id')}"
                    >
                      <option value>Select Reason</option>
                      <option
                        v-for="(cancellation_reason,index) in cancellation_reasons"
                        :value="cancellation_reason.reason_id"
                        v-bind:key="index"
                      >{{ cancellation_reason.reason }}</option>
                    </select>
                    <span
                      v-show="errors.has('reason_id')"
                      class="help is-danger"
                    >{{ $entrallfldrqrd }}</span>
                  </div>
                </div>
                </div>
                <div class="row mx-0 justify-content-center button-row">    
                  <button type="submit" :style="colorObj" class="btn submit-btn hoverEffect">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="modal-body" v-show="cancel_order_loading==true">
          <div class="row">
            <div class="container">
              <p class="text-center">
                <img src="../../assets/images/loading.gif" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { bus } from "../../main";
import helper from "../../store/helper"
export default {
  name: "CancelOrder",
  mixins:[helper],
  data() {
    return {
      currency_symbol: "₹",
      userprofile: null,
      cancel_order_loading: false,
      cancellation_reasons: null,
      reason_id: '',
      cancel_order_data: {
        user_id: '',
        order_id: '',
        reason_id: '',
      },
      cancel_order_id: '',
      cancel_order_no: '',
    };
  },
  mounted() {
    if (localStorage.getItem("user") != null) {
      this.userprofile = JSON.parse(localStorage.getItem("user"));
    } else {
      this.userprofile = null;
    }
    this.currency_symbol = localStorage.getItem("currency_symbol") != null && localStorage.getItem("currency_symbol") != "null"?localStorage.getItem("currency_symbol"):"₹";
    bus.$on("cancel_order_id", data => {
      this.cancel_order_id = data;
      this.getOrderCancellationReasons();
    });
    bus.$on("cancel_order_no", data => {
      this.cancel_order_no = data;
    });
    window.$(this.$refs.cancelordermodal).on("hidden.bs.modal", this.closeModal);
    window.$(this.$refs.cancelordermodal).on("show.bs.modal", this.openModal);
    this.originalColor();
  },
  methods: {
   getOrderCancellationReasons() {
    this.cancel_order_loading = true;
      this.$store
        .dispatch("getordercancellationreasons")
        .then(response => {
          this.cancel_order_loading = false;
          var result = JSON.parse(JSON.stringify(response));
          if(result.status == 1){
            this.cancellation_reasons = result.data.cancelled;
            window.$("#OrderCancelModal").modal("show");
          } else {
            this.$toast.error(result.message);
          }
        });
    },
    submitCancelOrder(){
      this.$validator.validateAll().then(result => {
        if (result) {
          this.cancel_order_data.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
          this.cancel_order_data.order_id = this.cancel_order_id;
          this.cancel_order_data.reason_id = this.reason_id;
          this.$store
            .dispatch("customerordercancellation", this.cancel_order_data)
            .then(response => {
              var result = JSON.parse(JSON.stringify(response));
              window.$("#OrderCancelModal").modal("hide");
              if (result.status == "1") {
                localStorage.setItem("cancelled-order-id", this.cancel_order_id);
                localStorage.setItem("cancelled-order-no", this.cancel_order_no);
                this.$router.push("/ordercancelled");
              } else {
                this.$toast.error(result.message);
              }
            });
        }
      });
    },
    openModal(){
      this.cancellation_reasons = null;
    },
    closeModal(){
      this.cancellation_reasons = null;
      this.reason_id = '';
      this.$validator.reset();
    },
  }
};
</script>
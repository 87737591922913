<template>
  <section class="category" style="margin-bottom:15px;" v-if="propdata.length > 0" :class="iscustomtitleflag == 0 ? 'delete_tag' : ''">
    <div class="container">
      <h2 class="section-heading" v-if="iscustomtitleflag == 1">{{ iscustomtitle }}</h2>
      <h2 v-else class="section-heading"></h2>
      <div class="slick-wrapper">
        <div id="categoriesslider">
          <VueSlickCarousel v-bind="slick_settings">
            <div class="slide-item" v-for="(getshoping, index) in propdata" v-bind:key="index">
              <a href="javascript:void(0);" id="Fruits" class="catagory-box" @click="getStoreByCategory(getshoping)">
                <v-lazy-image class="image" :src="getshoping.category_image" src-placeholder="getshoping.category_image" />
                <h3>{{getshoping.shop_category_name}}</h3>
              </a> 
            </div>
          </VueSlickCarousel>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import VLazyImage from "v-lazy-image/v2";
export default {
name: 'Shopbycategory',
  components:{
      VueSlickCarousel,
      VLazyImage
  },
  props:{
    propdata:{
      type:Array,
       default: () => ['next', 'prev'],
    },
    iscustomtitle:{
      type:String,
    },
    iscustomtitleflag:{
      type: String,
    }
  },
  data() {
    return {
      slick_settings: {
        "rows": 2,
        "dots": false,
        "arrows": true,
        "infinite": true,
        "speed": 300,
        "slidesToShow": 3,
        "slidesToScroll": 3,
        "useCSS": true,
        "responsive": [
          {
            "breakpoint": 1200,
            "settings": {
              "slidesToShow": 2,
              "slidesToScroll": 2,
              "rows": 2,
            }
          },
          {
            "breakpoint": 768,
            "settings": {
              "slidesToShow": 2,
              "slidesToScroll": 2,
              "rows": 1,
            }
          },
          {
            "breakpoint": 480,
            "settings": {
              "centerPadding": "20px",
              "focusOnSelect": true,
              "centerMode": true,
              "slidesToShow": 1,
              "slidesToScroll": 1,
              "rows": 1,
            }
          },
        ]
      },
      store_id: '1',
      category: {
        category_id: '',
        category_name: '',
      }
    }
  },
  methods: {
    getStoreByCategory(category){
      document.body.classList.remove('top-menu-add-padding');
      if(localStorage.getItem("is_business_model") == 3){
        this.category.category_id = category.store.category_id;
        this.category.category_name = category.shop_category_name;
        localStorage.setItem("selected-category", JSON.stringify(this.category));
        this.store_id = localStorage.getItem("store_id");
        this.$router.push({ path: `/shopdetail?storeid=${this.store_id.toString()}` });
      } else {
        category.type = "category";
        localStorage.setItem("category-id",category.shop_category_id);
        this.$router.push({ path: `/search-category` });
      }
    }
  }
}
</script>